<template>
   <div class="common-layout">
    <el-container>
        <el-aside width="210px" style="background:#fff;box-shadow: rgba(0, 0, 0, 0.06) 0px 2px 3px 0px">
            <div class="menu-title">
                <span>行业分类</span> 
            </div>
            <el-scrollbar :style="{'height':(windowHeight-135)+'px'}">
                <ul>
                    <li class="menu-item" v-for="item in industryList" :key="item.code" :class="{'cur': selIndustryCode==item.code}">
                        <a @click.prevent="selIndustryFun(item.code)" :title="item.name">
                            <span>{{item.name}}</span>
                        </a>
                    </li>
                </ul>
            </el-scrollbar>
        </el-aside>
        <el-main  style="position: relative;padding:0px;">
            <el-scrollbar v-if="selIndustryCode==''"   :style="{'height':(windowHeight-71)+'px'}"  style='padding:10px;padding-top:0px;' >
                <!-- <ProviceOverview></ProviceOverview> -->
            </el-scrollbar>
            <el-scrollbar v-else class='affix-container'  :style="{'height':(windowHeight-71)+'px'}"  style='padding:10px;padding-top:0px;' >
                <el-affix target=".affix-container" :offset="71" >
                    <div class="cd_page_nav" >
                        <span @click="goAnchor('gm')">行业规模</span>
                        <span @click="goAnchor('xy')">行业效益</span>
                        <span @click="goAnchor('fx')">行业风险</span>
                        <span v-has='{action:"download"}' @click="goAnchor('bg')">行业报告</span>
                    </div>
                </el-affix>
                <span id='gm' class="anchor_txt" style="margin-top:20px;">行业规模</span>
                <div class="clearfix">
                    <div class="box" :style='{"width":item.width}' v-for="(item,index) in industryInfoList?.find(t=>t.industryCode==selIndustryCode && t.type=='gm')?.item" :key='item'>
                        <span class="span-txt">{{item.title}}</span>
                        <div :id='"gm_chart_"+selIndustryCode+"_"+index' style="width:100%;height:400px;" ></div>
                    </div>
                    <div class="box clearfix" style="width:calc(100% - 20px);"  v-for="item in regionSortList?.filter(t=>t.industryCode==selIndustryCode && t.type=='gm')" :key="item">
                        <div v-if='item.sortType=="speedline"'>
                            <span class="span-txt">{{item.title}}</span>
                            <el-table :data="item.dataList" :height="570" stripe style="width:100%;">
                                <el-table-column type="index" width="60" label="排名" />
                                <el-table-column v-for="(el,index) in item.sortFieldAry" :key="el" :sortable='index==0?false:true' :prop="el"   :label="el"   />
                            </el-table>
                        </div>
                        <div v-else-if='item.sortType=="chart"'>
                            <span class="span-txt">{{item.title}}</span>
                            <el-row >
                                <el-col :span="6">
                                    <ul class="box-item">
                                        <li v-for="el in item.dataList" :key="el" @click="getRegionSortList(selIndustryCode,'gm',el)" :class="{'active':el.itemId==item.selItemId}">
                                            <span>{{el.itemName}}</span>
                                        </li>
                                    </ul>
                                </el-col>
                                <el-col :span="18">
                                    <div :id='"gm_sort_chart_"+selIndustryCode' style="width:100%;height:570px;">
                                    </div>
                                </el-col>
                            </el-row>
                        </div>
                        <div v-else>
                            <span class="span-txt">{{item.title}}</span>
                            <el-table :data="item.dataList" :height="570" stripe style="width:100%;">
                                <el-table-column type="index" width="60" label="排名" />
                                <el-table-column prop="regionName" label="地区"  width="80" />
                                <el-table-column v-for="el in item.sortFieldAry" :key="el" sortable :prop="el"   :label="el"   />
                            </el-table>
                        </div>
                    </div>
                </div>
                <span id='xy' class="anchor_txt">行业效益</span>
                <div class="clearfix">
                    <div class="box" :style='{"width":item.width}' v-for="(item,index) in industryInfoList?.find(t=>t.industryCode==selIndustryCode && t.type=='xy')?.item" :key='item'>
                        <span class="span-txt">{{item.title}}</span>
                        <div :id='"xy_chart_"+selIndustryCode+"_"+index' style="width:100%;height:400px;"></div>
                    </div>
                    <div class="box clearfix" style="width:calc(100% - 20px);" v-for="item in regionSortList?.filter(t=>t.industryCode==selIndustryCode && t.type=='xy')" :key="item">
                        <div v-if='item.sortType=="speedline"'>
                            <span class="span-txt">{{item.title}}</span>
                            <el-table :data="item.dataList" :height="570" stripe style="width:100%;">
                                <el-table-column type="index" width="60" label="排名" />
                                <el-table-column v-for="(el,index) in item.sortFieldAry" :key="el" :sortable='index==0?false:true' :prop="el"   :label="el"   />
                            </el-table>
                        </div>
                        <div v-else-if='item.sortType=="chart"'>
                            <span class="span-txt">{{item.title}}</span>
                            <el-row >
                                <el-col :span="6">
                                    <ul class="box-item">
                                        <li v-for="el in item.dataList" :key="el" @click="getRegionSortList(selIndustryCode,'xy',el)" :class="{'active':el.itemId==item.selItemId}">
                                            <span>{{el.itemName}}</span>
                                        </li>
                                    </ul>
                                </el-col>
                                <el-col :span="18">
                                    <div :id='"xy_sort_chart_"+selIndustryCode' style="width:100%;height:570px;">
                                    </div>
                                </el-col>
                            </el-row>
                        </div>
                        <div v-else>
                            <span class="span-txt">{{item.title}}</span>
                            <el-table :data="item.dataList" :height="570" stripe style="width:100%;">
                                <el-table-column type="index" width="60" label="排名" />
                                <el-table-column prop="regionName" label="地区"  width="80" />
                                <el-table-column v-for="el in item.sortFieldAry" :key="el" sortable :prop="el"   :label="el"   />
                            </el-table>
                        </div>
                    </div>
                </div>
                <span id='fx' class="anchor_txt">行业风险</span>
                <div class="clearfix">
                    <div class="box" :style='{"width":item.width}' v-for="(item,index) in industryInfoList?.find(t=>t.industryCode==selIndustryCode && t.type=='fx')?.item" :key='item'>
                        <span class="span-txt">{{item.title}}</span>
                        <div :id='"fx_chart_"+selIndustryCode+"_"+index' style="width:100%;height:400px;"></div>
                    </div>
                    <div class="box clearfix" style="width:calc(100% - 20px);" v-for="item in regionSortList?.filter(t=>t.industryCode==selIndustryCode && t.type=='fx')" :key="item">
                        <div v-if='item.sortType=="speedline"'>
                            <span class="span-txt">{{item.title}}</span>
                            <el-table :data="item.dataList" :height="570" stripe style="width:100%;">
                                <el-table-column type="index" width="60" label="排名" />
                                <el-table-column v-for="(el,index) in item.sortFieldAry" :key="el" :sortable='index==0?false:true' :prop="el"   :label="el"   />
                            </el-table>
                        </div>
                        <div v-else-if='item.sortType=="chart"'>
                            <span class="span-txt">{{item.title}}</span>
                            <el-row >
                                <el-col :span="6">
                                    <ul class="box-item">
                                        <li v-for="el in item.dataList" :key="el" @click="getRegionSortList(selIndustryCode,'fx',el)" :class="{'active':el.itemId==item.selItemId}">
                                            <span>{{el.itemName}}</span>
                                        </li>
                                    </ul>
                                </el-col>
                                <el-col :span="18">
                                    <div :id='"fx_sort_chart_"+selIndustryCode' style="width:100%;height:570px;">
                                    </div>
                                </el-col>
                            </el-row>
                        </div>
                        <div v-else>
                            <span class="span-txt">{{item.title}}</span>
                            <el-table :data="item.dataList" :height="570" stripe style="width:100%;">
                                <el-table-column type="index" width="60" label="排名" />
                                <el-table-column prop="regionName" label="地区"  width="80" />
                                <el-table-column v-for="el in item.sortFieldAry" :key="el" sortable :prop="el"   :label="el"   />
                            </el-table>
                        </div>
                    </div>
                </div>
                <div v-has='{action:"download"}'>
                    <span  id='bg' class="anchor_txt"> 行业报告</span>
                    <div class="box clearfix" style="width:calc(100% - 20px);">
                        <div v-has='{action:"download",rightType:0}'>
                            <el-table :data="reportList" :height="570" stripe style="width:100%;">
                                <el-table-column prop="ecoIndustryName" label="行业" width='150' />
                                <el-table-column prop="date"   label="报告年份" width='80'  >
                                    <template #default="scope">
                                        <span>{{ parseTime(new Date(scope.row.date),'{yyyy}')}}</span>
                                    </template>
                                </el-table-column>
                                <el-table-column prop="title"   label="报告标题"   >
                                    <template #default="scope">
                                        <el-link :underline="false" :href="`/#/pdf?path=${scope.row.path}`" target='_blank' >{{scope.row.title}}</el-link>
                                    </template>
                                </el-table-column>
                            </el-table>
                            <el-pagination
                                class="page-cus"
                                @current-change="handleCurrentChangeFun"
                                :current-page="reportPageIndex"
                                :hide-on-single-page="reportTotalCount<=reportPageSize?true:false"
                                layout="prev, pager, next"
                                :total="reportTotalCount">
                            </el-pagination>
                        </div>
                        <div class="limit-inside" v-has='{action:"download",rightType:1}'>
                            <RightLimit  url='3b39fa76.png' ></RightLimit>
                        </div>
                    </div>
                </div>
            </el-scrollbar>
        </el-main>
    </el-container>
  </div>
</template>
<script>
//import ProviceOverview from '@/components/industry/proviceOverview.vue'
import RightLimit from '@/components/RightLimit.vue'
import store from '../../store'
import { ref } from '@vue/reactivity'
import { useRouter } from 'vue-router';
import { computed, onMounted, onBeforeUnmount, nextTick, watch } from '@vue/runtime-core';

// import {getNextCodeNicList,addUserLog,getUserRightFun} from '@/http/basicsApi.js'
import {getNextCodeNicList,addUserLog} from '@/http/basicsApi.js'
import {getRegionIndustryDataList,getMultiRegionItemDataList,getItemList,getItemListOfEcoIndustry,getRegionItemSort,getNextRegionIndustryDataList,getEcoIndustryReportList,getEcoIndustryCurrentDate,getCurrentDate} from '@/http/ecoApi.js'
import * as echarts from 'echarts'
import {getDateList,parseTime,colors} from '@/utils/index' //parseTime
import {scrollTo} from '@/utils/scroll-to'
// 引入封装的nprogress.js
import { start, close } from '@/utils/nprogress';
//import axios from 'axios'
//import { ElLoading } from 'element-plus'
//import axios from 'axios';
export default {
    components:{
    //ProviceOverview
    RightLimit
  },
    setup() {
        const windowHeight =ref(document.documentElement.clientHeight);// 可视区的高度
        let echartsAry=ref([]);//echart对象
        let router=useRouter();
        //选中地区信息
        let regionCode=computed(()=> store.state.curRegionCode);
        //console.log(regionCode.value);
        //行业门类列表
        let industryList=ref();//行业分类
        //情况1：多个指标项一个行业，1、多个指标项 2、可以计算增速 3、图表系列如果不一致，根据指标项顺序排图表系列有增速再排指标顺序往后排 4、style 图表系列的样式，根据图表系列设置
        //情况2：多个行业一个指标项，一个图表系列
        //情况3：多个指标项多个行业，一个指标项对应一个行业
        let industryInfoList=ref([{industryCode:'A',type:'gm',item:[{title:'农林牧渔业总产值', itemId:'30007871',isSpeed:true,echartType:'bar,line'},{title:'农林牧渔产值', itemId:'30007872,30007873,30007874,30007875',echartType:'bar',style:'stack'},{title:'人口', itemId:'30009943,30007590',echartType:'bar', style:'stack'},{title:'种植面积',itemId:'30007884,30007849,30007404',echartType:'line',style:'area'}]},
        {industryCode:'A',type:'xy',item:[{title:'猪、牛、羊出栏量', itemId:'30000218,30000219,30000220',echartType:'bar',style:'stack'},{title:'人造板、木竹地板产量',itemId:'30007414,30007419',echartType:'bar'},{title:'农村居民人均可支配收入', itemId:'30007869',echartType:'line'},{title:'生产者价格指数', itemId:'30007435,30007436,30007437,30007438,30007439',echartType:'line'}]},
        {industryCode:'A',type:'fx',item:[{title:'农业受灾面积',itemId:'30008029',echartType:'bar'},{title:'农业成灾面积',itemId:'30008030',echartType:'line'}]},
        {industryCode:'B',type:'gm',item:[{title:'采矿业全社会固定资产投资', itemId:'20002776',isSpeed:true,echartType:'bar,line'},{title:'资产总计', itemId:'30000035',isSpeed:true,echartType:'bar,line'},{title:'存货',itemId:'30000005',echartType:'bar'},{title:'法人单位数',itemId:'20000148',echartType:'bar'},{title:'城镇单位就业人员', itemId:'20001662',echartType:'bar'}]},
        {industryCode:'B',type:'xy',item:[{title:'主营业务', itemId:'30000033,30000034',echartType:'line',style:'area'},{title:'利润', itemId:'30000012,30000206',echartType:'bar,line'},{title:'就业人员平均工资', itemId:'20001757',echartType:'bar'}]},
        {industryCode:'B',type:'fx',item:[{title:'负债合计', itemId:'30000006',echartType:'bar'},{title:'资产负债率',itemId:'30000216',echartType:'line'}]},
        {industryCode:'C',type:'gm',item:[{title:'制造业全社会固定资产投资', itemId:'20002777',echartType:'bar'},{title:'制造业法人单位数', itemId:'20000149',echartType:'line'},{title:'制造业城镇单位就业人员',itemId:'20001663',echartType:'line'}]},
        {industryCode:'C',type:'xy',item:[{title:'制造业城镇单位平均工资', itemId:'20001758',echartType:'bar'}]},
        {industryCode:'C',type:'fx',item:[{title:'制造业亏损企业亏损额', itemId:'30000011',isSpeed:true,echartType:'bar,line'},{title:'制造业负债合计', itemId:'30000006',isSpeed:true,echartType:'bar,line'}]},
        {industryCode:'D',type:'gm',item:[{title:'全社会固定资产投资',itemId:'20002778',echartType:'bar'},{title:'资产总计', itemId:'30000035',echartType:'line'},{title:'主营业务收入',itemId:'30000033',echartType:'line',style:'area'},{title:'工业销售产值',itemId:'30000009',echartType:'line',style:'area'},{title:'电力消费量排名',itemId:'20001559',echartType:'line'},{title:'城镇单位年末人数',itemId:'20004859',echartType:'line'}]},
        {industryCode:'D',type:'xy',item:[{title:'利润', itemId:'30000012,30000030',echartType:'bar'},{title:'费用', itemId:'30000001,30000010,30000028',echartType:'bar',style:'stack'},{title:'城镇单位平均工资', itemId:'20004862',echartType:'line'}]},
        {industryCode:'D',type:'fx',item:[{title:'负债', itemId:'30000006,30000007',echartType:'bar'},{title:'亏损', itemId:'30000011',echartType:'bar'}]},
        {industryCode:'E',type:'gm',item:[{title:'建筑业总产值', itemId:'20001952',echartType:'bar'},{title:'建筑业企业资产总计', itemId:'30000035',echartType:'bar'},{title:'建筑业企业主营业务收入', itemId:'30000033',echartType:'bar'},{title:'建筑企业单位数', itemId:'20001964',echartType:'line'},{title:'建筑业房屋施工面积', itemId:'20002102',echartType:'line',style:'area'},{title:'房屋竣工率', itemId:'30002079',echartType:'line'}]},
        {industryCode:'E',type:'xy',item:[{title:'建筑业企业利润总额', itemId:'30000012',echartType:'line',style:'area'},{title:'建筑业企业费用情况', itemId:'30000001,30000010,30000028',echartType:'bar',style:'stack'},{title:'城镇单位平均工资', itemId:'20004878',echartType:'line'},{title:'不同建筑业平均工资', itemId:'20004882,20004886,20004890,20004894',echartType:'line'}]},
        {industryCode:'E',type:'fx',item:[{title:'建筑业企业负债情况', itemId:'30000006,30000007',echartType:'bar'},{title:'建筑业亏损企业个数', itemId:'30002149',echartType:'line'},{title:'建筑业亏损企业的比重', itemId:'30002150',echartType:'line'}]},
        {industryCode:'F',type:'gm',item:[{title:'固定资产投资', itemId:'20002782',echartType:'bar'},{title:'大中型批发和零售业企业_资产总计', itemId:'30000096',echartType:'bar'},{title:'大中型批发和零售业企业_主营业务收入', itemId:'30000120',echartType:'bar'},{title:'批发业进出口', itemId:'20001106,20001108',echartType:'line',style:'area'},{title:'零售业进出口', itemId:'20001122,20001124',echartType:'line',style:'area'},{title:'大中型批发和零售企业商品销售额', itemId:'30000100',echartType:'line',style:'area'}]},
        {industryCode:'F',type:'xy',item:[{title:'利润总额', itemId:'30000135',echartType:'bar'},{title:'平均工资', itemId:'20004902,20004906',echartType:'line'}]},
        {industryCode:'F',type:'fx',item:[{title:'负债合计', itemId:'30000123',echartType:'bar'},{title:'负债率',itemId:'30000072,30000144',echartType:'line'}]},
        {industryCode:'G',type:'gm',item:[{title:'全社会固定资产投资', itemId:'20002780',isSpeed:true,echartType:'bar,line'},{title:'全社会客货运量', itemId:'30003540,30003542',echartType:'line'},{title:'全社会旅客、货运周转量', itemId:'30003541,30003543',echartType:'line'},{title:'民用汽车新注册', itemId:'30003569,30003574,30003579',echartType:'bar',style:'stack'},{title:'进口汽车拥有量', itemId:'30000324,30000326,30000330,30000332',echartType:'bar',style:'stack,y'},{title:'城市公共交通客运量', itemId:'30003596,30003597,30003598,30003599',echartType:'bar',style:'stack,y'},{title:'快递数量', itemId:'30000346',echartType:'line',style:'area'}]},
        {industryCode:'G',type:'xy',item:[{title:'交通运输、仓储、和邮政业增加值', itemId:'20002718',isSpeed:true,echartType:'bar,line'},{title:'就业人员工资',itemId:'20001742,20001761',echartType:'bar,line'},{title:'快递业务收入', itemId:'30000347',echartType:'bar'},{title:'地方财政交通运输支出', itemId:'20000047',echartType:'bar'}]},
        {industryCode:'G',type:'fx',item:[{title:'道路交通事故情况', itemId:'30003605',echartType:'line'},{title:'直接财产损失',itemId:'30003608',echartType:'bar'},{title:'道路交通事故死亡、受伤人数',itemId:'30003606,30003607',echartType:'line',style:'area'}]},
        {industryCode:'H',type:'gm',item:[{title:'全社会固定资产投资',itemId:'20002783',echartType:'bar'},{title:'大中型资产总计',itemId:'30000096',echartType:'line'},{title:'大中型主营业务收入',itemId:'30000120',echartType:'line'},{title:'限额以上住宿和餐饮营业额',itemId:'30000139',echartType:'bar'},{title:'限额以上住宿和餐饮营业额(客房收入、餐费)',itemId:'30000140,30000141',echartType:'bar'},{title:'大中型住宿和餐饮各种营业额',itemId:'30000086,30000087,30000089',echartType:'line',style:'area,stack'}]},
        {industryCode:'H',type:'xy',item:[{title:'主营业务利润',itemId:'20000381,20000394',echartType:'bar',style:'stack'},{title:'平均工资', itemId:'20004950,20004954',echartType:'line'},{title:'利润总额',itemId:'30000135',echartType:'bar'}]},
        {industryCode:'H',type:'fx',item:[{title:'大中型负债合计', itemId:'30000091',echartType:'line',style:'area'},{title:'资产负债率',itemId:'30000202',echartType:'line',style:'area'},{title:'大、中型负债率',itemId:'30000072,30000144',echartType:'line',style:'area,line'}]},
        {industryCode:'I',type:'gm',item:[{title:'全社会固定资产投资', itemId:'20002781',echartType:'bar'},{title:'资产总计', itemId:'30000035',echartType:'bar'},{title:'邮电业务总量', itemId:'30009755,30009756',echartType:'bar',style:'stack'},{title:'软件业务出口', itemId:'30009754',echartType:'bar'},{title:'国内专利授权数', itemId:'30009757',echartType:'line'},{title:'技术市场成交额', itemId:'30009758',echartType:'line',style:'area'},{title:'法人单位数', itemId:'30000122',echartType:'line'},{title:'从业人员', itemId:'30000004',echartType:'line'}]},
        {industryCode:'I',type:'xy',item:[{title:'营业收入', itemId:'30000031,30000210',echartType:'bar,line'},{title:'各类收入',itemId:'30009750,30009751,30009752,30009753',echartType:'bar'},{title:'工资总额', itemId:'20001743,20001762',echartType:'bar,line'}]},
        {industryCode:'I',type:'fx',item:[{title:'总资产周转率', itemId:'30000217',echartType:'line',width:'100%'}]},
        {industryCode:'J',type:'gm',item:[{title:'全社会固定资产投资', itemId:'20002784',echartType:'bar'},{title:'金融机构住户存款余额_住户存款',itemId:'30006961,30006962',echartType:'bar'},{title:'小额贷款公司基本情况_贷款余额', itemId:'30006979',echartType:'line',style:'area'},{title:'社会融资规模增量',itemId:'30006980',echartType:'line',style:'area'},{title:'上市公司家数', itemId:'30000363,30000364',echartType:'line'},{title:'城镇职工参加养老保险人数',itemId:'20002436',echartType:'line'},{title:'中国农业银行人民币存、贷款余额', itemId:'30007020,30007021',echartType:'line',style:'area'},{title:'交通银行本外币存、贷款余额',itemId:'30007024,30007025',echartType:'line',style:'area'}]},
        {industryCode:'J',type:'xy',item:[{title:'保险保费收入',itemId:'30000365',echartType:'bar'},{title:'财产险、人身险',itemId:'30000366,30000367',echartType:'bar',style:'stack,y'},{title:'金融业城镇单位就业人员工资总额',itemId:'20001746',echartType:'bar'},{title:'平均工资',itemId:'20001765',echartType:'line'},{title:'基本养老保险基金收入',itemId:'20002439',echartType:'bar'}]},
        {industryCode:'J',type:'fx',item:[{title:'养老保险收入支出',itemId:'20002439,20002440',echartType:'line'},{title:'养老保险累计结余',itemId:'20002441',echartType:'bar'},{title:'商业银行不良贷款余额',itemId:'30007034',echartType:'line',style:'area'},{title:'商业银行不良贷款率',itemId:'30007035',echartType:'line'},{title:'原保险保费赔付支出',itemId:'30000368',echartType:'bar'},{title:'财产险、人身险赔付支出',itemId:'30000369,30000370',echartType:'bar',style:'stack'}]},
        {industryCode:'K',type:'gm',item:[{title:'全社会固定资产投资', itemId:'20002785',echartType:'bar'},{title:'房地产开发投资',itemId:'20002754',echartType:'bar'},{title:'资产总计', itemId:'30000035',echartType:'line'},{title:'主营业务收入',itemId:'30000033',echartType:'line'},{title:'法人单位数', itemId:'20000157',echartType:'line'},{title:'待开发、购置土地面积',itemId:'20002908,20002909',echartType:'line',style:'area'},{title:'施工、竣工房屋面积', itemId:'20002930,20002931',echartType:'line',style:'area'},{title:'商品房销售面积',itemId:'30001116',echartType:'bar'},{title:'按用途分的商品房销售面积', itemId:'30001117,30001119,30001120,30001121',echartType:'bar',style:'stack'}]},
        {industryCode:'K',type:'xy',item:[{title:'利润',itemId:'30000012,30000030',echartType:'bar'},{title:'城镇单位就业人员平均工资',itemId:'20001766',echartType:'line'},{title:'商品房销售额',itemId:'30001197',echartType:'bar'},{title:'按用途分商品房销售额',itemId:'30001198,30001200,30001201,30001202',echartType:'bar',style:'stack'}]},
        {industryCode:'K',type:'fx',item:[{title:'负债合计',itemId:'30000006',echartType:'bar'},{title:'资产负债率',itemId:'30000216',echartType:'line'},{title:'房屋建筑面积竣工率',itemId:'20002932',echartType:'line'},{title:'商品房待售面积',itemId:'30001352',echartType:'line',style:'area'},{title:'按用途分商品房待售面积',itemId:'30001353,30001354,30001355,30001356,30001357',echartType:'bar',style:'stack,y'}]},
        {industryCode:'L',type:'gm',item:[{title:'全社会固定资产投资',itemId:'20002786',echartType:'bar'},{title:'资产总计',itemId:'30000035',echartType:'bar'},{title:'法人单位数',itemId:'20000158',echartType:'line'},{title:'城镇单位就业人员',itemId:'20001672',echartType:'line'}]},
        {industryCode:'L',type:'xy',item:[{title:'营业收入',itemId:'30000031',echartType:'bar'},{title:'收入增长率',itemId:'30000210',echartType:'line'},{title:'租赁、商务服务业的工资总额',itemId:'20005013,20005017',echartType:'bar'},{title:'租赁、商务服务业的平均工资',itemId:'20005014,20005018',echartType:'line'}]},
        {industryCode:'L',type:'fx',item:[{title:'总资产周转率',itemId:'30000217',echartType:'line'}]},

        {industryCode:'M',type:'gm',item:[{title:'全社会固定资产投资',itemId:'20002787',echartType:'bar'},{title:'年末人数',itemId:'20005031,20005027,20005023',echartType:'line'},{title:'高等学校R&D人员_学校数',itemId:'30007078',echartType:'bar'},{title:'高等学校R&D课题_投入经费',itemId:'30007170',echartType:'bar'},{title:'规上工业企业R&D人员',itemId:'30007046',echartType:'line'},{title:'国内三种专利申请数',itemId:'30007205,30007206,30007207',echartType:'bar',style:'stack,y'}]},
        {industryCode:'M',type:'xy',item:[{title:'新产品销售收入',itemId:'30007073,30007074',echartType:'bar'},{title:'技术市场成交额',itemId:'30000386',echartType:'line',style:'area',ecoIndustry:'M75'},{title:'工资总额',itemId:'20005033,20005029,20005025',echartType:'bar',style:'stack'},{title:'平均工资',itemId:'20005034,20005030,20005026',echartType:'line'}]},
        {industryCode:'M',type:'fx',item:[{title:'研究与试验发展(R&D)经费投入强度',itemId:'30007249',echartType:'line',ecoIndustry:'M73'},{title:'产品质量情况',itemId:'30000379,30000380,30000381',echartType:'line',ecoIndustry:'M75'}]},
        {industryCode:'N',type:'gm',item:[{title:'全社会固定资产投资',itemId:'20002788',echartType:'bar'},{title:'资产总计',itemId:'30000035',echartType:'bar'},{title:'法人单位数',itemId:'20000160',echartType:'line'},{title:'城镇单位就业人员',itemId:'20001674',echartType:'line'},{title:'灌溉面积',itemId:'30008180',echartType:'line',style:'area'},{title:'造林总面积',itemId:'30001726',echartType:'line',style:'area'},{title:'城镇环境基础设施建设投资总额',itemId:'30001829',echartType:'line',style:'area'}]},
        {industryCode:'N',type:'xy',item:[{title:'营业收入',itemId:'30000031',echartType:'bar'},{title:'平均工资',itemId:'20001769',echartType:'line'},{title:'人均用水量',itemId:'30001659',echartType:'line',style:'area'},{title:'用水、燃气普及率',itemId:'30001877,30001933',echartType:'line'},{title:'绿化覆盖率',itemId:'30001947',echartType:'line'},{title:'用水重复利用率',itemId:'30001886',echartType:'line'}]},
        {industryCode:'N',type:'fx',item:[{title:'总资产周转率',itemId:'30000217',echartType:'line'},{title:'直接经济损失',itemId:'30001776',echartType:'bar'},{title:'废水排放总量',itemId:'30000439',echartType:'bar'},{title:'处理率',itemId:'30001801,30001899,30001916',echartType:'line'}]},
        {industryCode:'O',type:'gm',item:[{title:'全社会固定资产投资',itemId:'20002789',echartType:'bar'},{title:'资产总计',itemId:'30000035',echartType:'bar'},{title:'法人单位数',itemId:'20000161',echartType:'line'},{title:'城镇单位就业人员',itemId:'20001675',echartType:'line'}]},
        {industryCode:'O',type:'xy',item:[{title:'营业收入',itemId:'30000031',echartType:'bar'},{title:'收入增长率',itemId:'30000210',echartType:'line'},{title:'城镇单位就业人员工资总额',itemId:'20001751',echartType:'bar'},{title:'城镇单位就业人员平均工资',itemId:'20001770',echartType:'line'}]},
        {industryCode:'O',type:'fx',item:[{title:'总资产周转率',itemId:'30000217',echartType:'line'}]},
        {industryCode:'P',type:'gm',item:[{title:'全社会固定资产投资',itemId:'20002790',echartType:'bar'},{title:'教育经费',itemId:'20001875',echartType:'bar'},{title:'固定资产总值',itemId:'30004923,30005556,30005566,30005576,30005586,30005907,30006405',echartType:'bar',style:'stack'},{title:'教职工数',itemId:'30004243,30004656,30005207,30005225,30005261,30005279,30005518,30005536,30005545,30006132,30006483,30006615,30006698',echartType:'line'},{title:'学校数',itemId:'30004559,30005512,30005530,30005539,30005588,30005980,30006478,30006485',echartType:'bar',style:'stack'},{title:'每十万人口各级学校平均在校生数',itemId:'20004506,20004507,20004508,20004509,20004510',echartType:'line',style:'stack,area'},{title:'毕业的研究生数',itemId:'30003801,30003818',echartType:'line',style:'stack,area'}]},
        {industryCode:'P',type:'xy',item:[{title:'城镇单位就业人员工资总额',itemId:'20005073,20005077,20005081',echartType:'bar'},{title:'城镇单位就业人员平均工资',itemId:'20005074,20005078,20005082',echartType:'line'}]},
        {industryCode:'P',type:'fx',item:[{title:'就业人员受教育程度',itemId:'20005942,20005943,20005944,20005945,20005946,20005947,20005948,20005949,20005950',echartType:'line'},{title:'各教育阶段就业人口比例',itemId:'20008922,20008923,20008924,20008925,20008926,20008927,20008928,20008929,20008930',echartType:'line'}]},
        {industryCode:'Q',type:'gm',item:[{title:'全社会固定资产投资',itemId:'20002791',echartType:'bar'},{title:'医疗卫生机构资产',itemId:'30008866',echartType:'bar',ecoIndustry:'Q84'},{title:'人均卫生总费用',itemId:'30008859',echartType:'line',ecoIndustry:'Q84'},{title:'法人单位数',itemId:'20000163',echartType:'line'},{title:'城镇单位就业人员',itemId:'20001677',echartType:'line'},{title:'养老机构数',itemId:'30008087',echartType:'bar',ecoIndustry:'Q85'},{title:'医疗卫生机构数',itemId:'30008550,30008557,30008565,30008574',echartType:'bar',ecoIndustry:'Q84'}]},
        {industryCode:'Q',type:'xy',item:[{title:'城镇单位就业人员工资总额',itemId:'20001753',echartType:'bar'},{title:'城镇单位就业人员平均工资',itemId:'20001772',echartType:'line'},{title:'医疗卫生机构收入',itemId:'30008871',echartType:'bar',ecoIndustry:'Q84'},{title:'医院门诊和住院病人人均医药费用',itemId:'30008878,30008881',echartType:'bar',style:'stack',ecoIndustry:'Q84'},{title:'城乡居民医疗保健支出_人均年消费支出',itemId:'30008860,30008863',echartType:'bar',ecoIndustry:'Q84'},{title:'医疗保健支出占消费性支出比例',itemId:'30008862,30008865',echartType:'line',ecoIndustry:'Q84'}]},
        {industryCode:'Q',type:'fx',item:[{title:'医疗卫生机构负债',itemId:'30008869',echartType:'bar',ecoIndustry:'Q84'},{title:'医疗卫生机构病死率',itemId:'20000802,20000803',echartType:'line'},{title:'乡镇卫生院病床使用率',itemId:'20000820',echartType:'line'}]},
        {industryCode:'R',type:'gm',item:[{title:'全社会固定资产投资',itemId:'20002792',echartType:'bar'},{title:'法人单位数',itemId:'20000164',echartType:'line'},{title:'城镇单位就业人员',itemId:'20001678',echartType:'line'},{title:'资产总计',itemId:'30000035',echartType:'bar'},{title:'各行业机构资产总计',itemId:'30009312,30009328,30009348,30009362,30009377',echartType:'bar',style:'stack'},{title:'机构数',itemId:'30009302,30009317,30009335,30009352,30009367,30009381',echartType:'bar',style:'stack'}]},
        {industryCode:'R',type:'xy',item:[{title:'营业收入', itemId:'30000031',echartType:'bar'},{title:'城镇单位就业人员工资总额', itemId:'20001754,20001773',echartType:'bar,line'},{title:'各机构营业收入', itemId:'30009545',echartType:'bar',style:'stack',ecoIndustry:'R87'},{title:'营业利润', itemId:'30009437,30009607,30009728,30009737,30009743',echartType:'line',style:'stack,area',ecoIndustry:'R86,R88,R90'},{title:'收入增长率', itemId:'30000210',echartType:'line'},{title:'居民人均文化娱乐消费支出', itemId:'30009584',echartType:'line',ecoIndustry:'R88'}]},
        {industryCode:'R',type:'fx',item:[{title:'负债', itemId:'30009434,30009563',echartType:'bar',style:'stack'},{title:'总资产周转率',itemId:'30000217',echartType:'line'}]},
        {industryCode:'S',type:'gm',item:[{title:'全社会固定资产投资',itemId:'20002793',echartType:'line'},{title:'法人单位数',itemId:'20000165',echartType:'line'},{title:'城镇单位就业人员',itemId:'20001679',echartType:'line'},{title:'基金收入',itemId:'30000401,30000406,30000411,30000419,30000430,30000435',echartType:'line',sytle:'area,stack'}]},
        {industryCode:'S',type:'xy',item:[{title:'城镇单位就业人员工资总额',itemId:'20001755',echartType:'bar'},{title:'城镇单位就业人员平均工资',itemId:'20001774',echartType:'line'}]},
        {industryCode:'S',type:'fx',item:[{title:'交通事故情况',itemId:'30000396,30000397',echartType:'line'},{title:'交通事故直接财产损失',itemId:'30000398',echartType:'bar'}]},
        ]);
        let regionSortList=ref([{industryCode:'A',type:'xy',title:'各省排名',itemId:'30007876,30007877,30007878,30007879,30007880,30007881,30007882,30007883',sortType:'list',dataList:[],selItemId:''},
        {industryCode:'B',type:'gm',title:'各省排名',itemId:'20002776;30000033,30000035',sortType:'chart',dataList:[],selItemId:''},
        {industryCode:'B',type:'xy',title:'各省排名',itemId:'30000185,30000186,30000204,30000205,30000206,30000208,30000209,30000210,30000213,30000214,30000215',sortType:'chart',dataList:[],selItemId:''},
        {industryCode:'B',type:'fx',title:'各省排名',itemId:'30000184,30000207,30000211,30000212,30000216,30000217',sortType:'chart',dataList:[],selItemId:''},
        {industryCode:'C',type:'gm',title:'不同制造业资产总计',itemId:'30000035',sortType:'speedline',ecoIndustry:'children',dataList:[]},
        {industryCode:'C',type:'gm',title:'各省排名',itemId:'20002777;30000033,30000035',sortType:'chart',dataList:[],selItemId:''},
        {industryCode:'C',type:'xy',title:'不同制造业城镇单位就业人员的平均工资', itemId:'20004738,20004742,20004746,20004750,20004754,20004758,20004762,20004766,20004770,20004774,20004778,20004782,20004786,20004790,20004794,20004798,20004802,20004806,20004810,20004814,20004818,20004822,20004826,20004830,20004834,20004838,20004842,20004846,20004850,20004854,20004858',sortType:'speedline',dataList:[]},
        {industryCode:'C',type:'xy',title:'各省排名',itemId:'30000185,30000186,30000204,30000205,30000206,30000208,30000209,30000210,30000213,30000214,30000215',sortType:'chart',dataList:[],selItemId:''},
        {industryCode:'C',type:'fx',title:'不同制造业亏损',itemId:'30000011',sortType:'speedline',ecoIndustry:'children',dataList:[]},
        {industryCode:'C',type:'fx',title:'不同制造业负债合计', itemId:'30000006',sortType:'speedline', style:'stack',ecoIndustry:'children',dataList:[]},
        {industryCode:'C',type:'fx',title:'各省排名',itemId:'30000184,30000207,30000211,30000212,30000216,30000217',sortType:'chart',dataList:[],selItemId:''},
        {industryCode:'D',type:'gm',title:'各省排名',itemId:'20002778;30000033,30000035',sortType:'chart',dataList:[],selItemId:''},
        {industryCode:'D',type:'xy',title:'各省排名',itemId:'30000185,30000186,30000204,30000205,30000206,30000208,30000209,30000210,30000213,30000214,30000215',sortType:'chart',dataList:[],selItemId:''},
        {industryCode:'D',type:'fx',title:'各省排名',itemId:'30000184,30000207,30000211,30000212,30000216,30000217',sortType:'chart',dataList:[],selItemId:''},
        {industryCode:'E',type:'gm',title:'各省排名',itemId:'20002779;30000033,30000035',sortType:'chart',dataList:[],selItemId:''},
        {industryCode:'E',type:'xy',title:'各省排名',itemId:'30000185,30000186,30000204,30000205,30000206,30000208,30000209,30000210,30000213,30000214,30000215',sortType:'chart',dataList:[],selItemId:''},
        {industryCode:'E',type:'fx',title:'各省排名',itemId:'30000184,30000207,30000211,30000212,30000217,30000216',sortType:'chart',dataList:[],selItemId:''},
        {industryCode:'F',type:'gm',title:'各省排名',itemId:'20002782;30000096,30000120',sortType:'chart',dataList:[],selItemId:''},
        {industryCode:'F',type:'xy',title:'各省排名',itemId:'30000189,30000190,30000191,30000192,30000193,30000194,30000195,30000196,30000199,30000200,30000201',sortType:'chart',dataList:[],selItemId:''},
        {industryCode:'F',type:'fx',title:'各省排名',itemId:'30000188,30000197,30000198,30000202,30000203',sortType:'chart',dataList:[],selItemId:''},
        {industryCode:'H',type:'gm',title:'各省排名',itemId:'20002783;30000096,30000120',sortType:'chart',dataList:[],selItemId:''},
        {industryCode:'H',type:'xy',title:'各省排名',itemId:'30000189,30000190,30000191,30000192,30000193,30000194,30000195,30000196,30000199,30000200,30000201',sortType:'chart',dataList:[],selItemId:''},
        {industryCode:'H',type:'fx',title:'各省排名',itemId:'30000188,30000197,30000198,30000202,30000203',sortType:'chart',dataList:[],selItemId:''},
        {industryCode:'K',type:'gm',title:'各省排名',itemId:'20002785;30000033,30000035',sortType:'chart',dataList:[],selItemId:''},
        {industryCode:'K',type:'xy',title:'各省排名',itemId:'30000185,30000186,30000204,30000205,30000206,30000208,30000209,30000210,30000213,30000214,30000215',sortType:'chart',dataList:[],selItemId:''},
        {industryCode:'K',type:'fx',title:'各省排名',itemId:'30000184,30000207,30000211,30000212,30000216,30000217',sortType:'chart',dataList:[],selItemId:''},
        ])
        let selIndustryCode=ref('');//选中行业的索引
        //行业报告列表
        let reportTotalCount=ref(0);
        let reportPageSize=ref(10);
        let reportPageIndex=ref(1);
        let reportList=ref([]);//报告列表
        //行业分类
        const getIndustryListFun= async()=>{
            let result=await getNextCodeNicList('');
            //console.log(result);
            if(result.code==20000){
                industryList.value=result.data.filter(t=>t.code!='T');
                //industryList.value.unshift({code:'',name:'行业总览'})
                //console.log(result.data)
                //console.log(industryList.value)
                selIndustryCode.value=industryList.value[0].code;
                if(selIndustryCode.value!=''){
                     //store.commit("setPrevRequest", selIndustryCode.value);
                    getItemDataListFun();
                    //排名
                    getSortItemListFun();
                    //行业报告
                    getReportListFun();
                    
                }
                //记录用户操作日志
                addUserLog(router.currentRoute.value.name,router.currentRoute.value.fullPath,'加载',`{"regionCode":"${regionCode.value}","industryCode":"${selIndustryCode.value}"}`);
               
            }
        }
        //选择行业分类
        const selIndustryFun=async (code)=>{
            start();
            //加载等级
            // const loading = ElLoading.service({
            //     lock: true,
            //     text: '数据加载……',
            //     background: 'rgba(0,0,0, 0.1)',
            // })
            //if(code!=selIndustryCode.value){

                selIndustryCode.value=code;
                
                if(selIndustryCode.value!=''){
                    nextTick(()=>{
                        document.querySelector('.affix-container .el-scrollbar__wrap').scrollTop=0
                    })
                    reportPageIndex.value=1;
                    reportList.value=[];
                    getItemDataListFun();
                    //排名
                    getSortItemListFun();
                    //行业报告
                    getReportListFun();
                }
            //}
            //loading.close();//关闭加载等级
            close();
            //记录用户操作日志
            addUserLog(router.currentRoute.value.name,router.currentRoute.value.fullPath,'选行业',`{"regionCode":"${regionCode.value}","industryCode":"${selIndustryCode.value}"}`);
        }
        //获取当前行业的历史指标数据
        const getItemDataListFun=async()=>{
            //console.log(selIndustryCode.value); 
            //console.log(regionCode.value); 
            let objAry=industryInfoList.value.filter(t=>t.industryCode==selIndustryCode.value);
            for(var i=0;i<objAry.length;i++){
                let item=objAry[i].item;
                for(var j=0;j<item.length;j++){
                    // if(store.state.prevRequest!=selIndustryCode.value && objAry[i].industryCode==store.state.prevRequest){
                    //     store.commit("setPrevRequest", selIndustryCode.value);
                    //     return 
                    // }
                    //判断市宏观、行业指标项
                    let dateAry=getDateList('1');
                    let params={
                        itemId:item[j].itemId,
                        regionCode:regionCode.value,
                        dateType:1,
                        date:dateAry[0],
                        endDate:dateAry[dateAry.length-1],
                        objId:item[j].ecoIndustry?item[j].ecoIndustry:selIndustryCode.value,
                        isForecast:0,
                        pageSize:1
                    }
                    //console.log(params);
                    //改变行业时，如何上次的循环还没有执行完成则退出
                    if(objAry[i].industryCode!=selIndustryCode.value){
                        return 
                    }
                    let res=null;
                    let itemList=null;
                    if(item[j].itemId.substring(0,2)=='20'){//宏观
                        //获取指标项
                        itemList=await getItemList({itemId:item[j].itemId,pageSize:1})
                        //获取数据
                        res= await getMultiRegionItemDataList(params);
                    }
                    else {
                        //获取指标项,多个指标项单个行业；单个指标项多个行业
                        itemList=await getItemListOfEcoIndustry({itemId:item[j].itemId,pageSize:1})
                        //获取数据
                        res= await getRegionIndustryDataList(params);
                    }
                    //console.log(itemList);
                    //console.log(res);
                    if(res.code==20000){
                        let seriesInfo=[];
                        
                            //一个行业多个指标项，多个series,根据单位不同分多个y轴,最多两个。
                            let itemIds=item[j].itemId.split(',');
                            let itemUnitAry=[...new Set(itemList.data.dataList.map(t=>t.itemUnit))] ;
                            itemIds.forEach((el,index)=>{
                                let seriesData=[];
                                let speedData=[];
                                let prevData=null;
                                let itemInfo=itemList.data.dataList.find(t=>t.itemId==el);
                                let seriesName=itemInfo.itemName;// itemInfo.itemName.substring(itemInfo.itemName.indexOf('_')+1);
                                dateAry.forEach(t=>{
                                    let itemObj=res.data.find(d=>new Date(d.date).getTime()==new Date(t).getTime() && d.itemId==el)
                                    seriesData.push(itemObj?itemObj.itemValue:null)
                                    if(item[j].isSpeed==true && prevData && itemObj && itemObj.itemValue){//计算增速
                                        speedData.push(((itemObj.itemValue-prevData)/itemObj.itemValue*100).toFixed(2));
                                    }
                                    else {
                                        speedData.push(null);
                                    }
                                    prevData=itemObj?itemObj.itemValue:null
                                })
                                let echartTypeAry= item[j].echartType.split(',');
                                let echartType=echartTypeAry.length>1?echartTypeAry[index]:echartTypeAry[0]
                                if(echartType=='line'){
                                    seriesInfo.push({
                                        name: seriesName,
                                        type:echartType,//echartType：系列的图形一致，就一个。
                                        yAxisIndex: itemUnitAry.indexOf(itemInfo.itemUnit),//最多两个y轴
                                        stack:item[j].style?.indexOf('stack')>=0?'total':null,
                                        tooltip: {
                                            valueFormatter: function (value) {
                                            return (value?value:'-')+itemInfo.itemUnit;
                                            }
                                        },
                                        data: seriesData,
                                        smooth: true,
                                        symbolSize: 6,
                                        symbol: 'circle',
                                        lineStyle: {
                                            width: 2,
                                            shadowColor: 'rgba(0,0,0,0.3)',
                                            shadowBlur: 8,
                                            shadowOffsetY: 6
                                        }
                                    });
                                    if(item[j].style?.indexOf('area')>=0){//相同的系列都用同一个style
                                        seriesInfo[seriesInfo.length-1].areaStyle ={}
                                    }
                                }
                                else if(echartType=='bar'){
                                    seriesInfo.push(
                                        {name: seriesName,
                                        type:echartType,//echartType：系列的图形一致，就一个。
                                        yAxisIndex: itemUnitAry.indexOf(itemInfo.itemUnit),//最多两个y轴
                                        stack:item[j].style?.indexOf('stack')>=0?'total':null,
                                        tooltip: {
                                            valueFormatter: function (value) {
                                            return (value?value:'-')+itemInfo.itemUnit;
                                            }
                                        },
                                        data: seriesData,
                                    });
                                }
                                // else if(echartType=='scatter'){

                                // }
                                
                                if(item[j].isSpeed==true){
                                    seriesInfo.push({
                                        name: '增速',
                                        yAxisIndex: 1,//最多两个y轴
                                        type:echartTypeAry.length>1?echartTypeAry[index+itemIds.length]:echartTypeAry[0],//echartType：系列图形不一致，用逗号隔开，几个指标项几个系列类型，增速按照指标项顺序往后添加系列类型
                                        tooltip: {
                                            valueFormatter: function (value) {
                                            return (value?value:'-') + '%';
                                            }
                                        },
                                        data: speedData
                                    })
                                }
                            })
                        initChart(objAry[i].type+'_chart_'+selIndustryCode.value+"_"+j,dateAry,seriesInfo,(','+item[j].style+',')?.indexOf(',y,')>=0?'y':'x');//(domId,type,data);
                    }
                }
            }
        }
        //初始化图表,x轴为时间轴
        const initChart=(domId,date,seriesInfo,axis)=>{// eslint-disable-line no-unused-vars 
            var chartDom = document.getElementById(domId);
            var myChart = echarts.getInstanceByDom(chartDom);
            if(myChart){
                myChart.clear();
            }
            else{
                myChart = echarts.init(chartDom);
            }
            let option = {
                color:colors,
                tooltip: {
                    trigger: 'axis',
                    axisPointer: {
                    type: 'cross',
                    crossStyle: {
                        color: '#999'
                    }
                    }
                },
                grid: {
                    left: '3%',
                    right: '4%',
                    bottom: '3%',
                    //top:30,
                    containLabel: true
                },
                legend: {
                    data: seriesInfo.map(t=>t.name)
                },
                xAxis: [
                    {
                    type: 'category',
                    data: date,
                    axisPointer: {
                        type: 'shadow'
                    },
                    axisLabel:{
                        formatter: function (params) {
                            return new Date(params).getFullYear()
                        }
                    }
                    }
                ],
                yAxis: [
                    {
                        type: 'value',
                    },
                    {
                        type: 'value',
                        alignTicks:true,
                    }
                ],
                series: seriesInfo
                };
            if(axis && axis=='y'){
                option.yAxis={
                    type: 'category',
                    data: date,
                    axisLabel:{
                        formatter: function (params) {
                            return new Date(params).getFullYear()
                        }
                    }
                }
                option.xAxis={
                    type: 'value'
                }
            }
            option && myChart.setOption(option);
            echartsAry.value.push(myChart);
        }
        //获取省级排名的指标信息列表
        const getSortItemListFun=async ()=>{
            let objAry=regionSortList.value.filter(t=>t.industryCode==selIndustryCode.value);
            for(var i=0;i<objAry.length;i++){
                if(objAry[i].industryCode!=selIndustryCode.value){
                    return
                }
                objAry[i].dataList=[];
                if(objAry[i].sortType=='list'){//获取指标数据，指标项要行转列
                    let params={
                        itemId:objAry[i].itemId,
                        regionCode:'0',
                        objId:selIndustryCode.value,
                        dateType:2,
                        pageSize:1
                    }
                    let res =await getItemListOfEcoIndustry({itemId:objAry[i].itemId,pageSize:1});
                    let resp=await getNextRegionIndustryDataList(params)
                    //console.log(res);
                    //console.log(resp);
                    if(resp.code==20000 && objAry[i].industryCode==selIndustryCode.value){
                        objAry[i].dataList=[];
                        let regionList=[...new Set( resp.data.map(t=>t.regionCode))];
                        regionList.forEach(t=>{
                            let sortList={};
                            let sortFieldAry=[];
                            sortList.regionName=resp.data.find(el=>el.regionCode==t).regionName;
                            res.data.dataList.forEach(el=>{
                                let obj=resp.data.find(d=>d.regionCode==t && d.itemId==el.itemId);
                                let fieldName=el.itemName.substring(el.itemName.indexOf('_')+1)+'('+el.itemUnit+')';
                                sortFieldAry.push(fieldName);
                                sortList[fieldName]=obj?obj.itemValue:null;
                            })
                            objAry[i].dataList.push(sortList);
                            objAry[i].sortFieldAry=sortFieldAry;
                        })
                    }
                }
                else if(objAry[i].sortType=='speedline'){//行业某个指标项·增速排名
                    let params={
                        itemId:objAry[i].itemId,
                        regionCode:regionCode.value,
                        dateType:3,//多个时间点
                        date:'2020-01-01,2019-01-01,2015-01-01',
                        objId:null,
                        isForecast:0,
                        pageSize:1
                    }
                    if(objAry[i].ecoIndustry && objAry[i].ecoIndustry=='children'){//当前行业的子级
                        let industryList=[];
                        let industryRes=await getNextCodeNicList(objAry[i].industryCode);
                        industryList=industryRes.data;
                        //行业信息
                        //行业指标项的最新时间
                        let dateRes= getEcoIndustryCurrentDate({
                            itemId:objAry[i].itemId,
                            regionCode:regionCode.value,
                            ecoIndustryCode:industryList.map(t=>t.code).join(',')})
                        //行业指标项最新时间、上年、前5年的数据
                        let date=dateRes.data;
                        if(!date){
                            date=(new Date().getFullYear()-3)+'-01-01';
                        }
                        //console.log(date);
                        params.date=date+','+(new Date(date).getFullYear()-1)+'-01-01,'+(new Date(date).getFullYear()-5)+'-01-01';
                        params.objId=industryList.map(t=>t.code).join(',');

                        let res= await getRegionIndustryDataList(params);
                        if(res.code==20000){
                            //计算与上年的增速，与前5年的增速
                            let sortList=[];
                            let dateAry=params.date.split(',');
                            industryList.forEach((t,index)=>{
                                let sortObj={};
                                sortObj['行业']=t.name;
                                //本年度的数据
                                let obj= res.data.find(d=>new Date(d.date).getTime()==new Date(dateAry[0]).getTime() && d.ecoIndustryCode==t.code);
                                let filed_1=parseTime( new Date(dateAry[0]),'{yyyy}')+'年';
                                sortObj[filed_1]=obj?obj.itemValue:null;
                                //上年度的数据
                                let prev_obj= res.data.find(d=>new Date(d.date).getTime()==new Date(dateAry[1]).getTime() && d.ecoIndustryCode==t.code);
                                let filed_2=parseTime( new Date(dateAry[1]),'{yyyy}')+'年';
                                sortObj[filed_2]=prev_obj?prev_obj.itemValue:null;
                                //与上年的增速
                                sortObj['与上年增速']=prev_obj && obj && prev_obj.itemValue && obj.itemValue && prev_obj.itemValue!=0 ?Math.round((obj.itemValue-prev_obj.itemValue)/prev_obj.itemValue*100 ,2) :null;
                                //五年前的数据
                                let five_obj= res.data.find(d=>new Date(d.date).getTime()==new Date(dateAry[2]).getTime() && d.ecoIndustryCode==t.code);
                                let filed_3=parseTime( new Date(dateAry[2]),'{yyyy}')+'年';
                                sortObj[filed_3]=five_obj?five_obj.itemValue:null;
                                //与五年前的增速
                                sortObj['与五年前增速']=five_obj && obj && five_obj.itemValue && obj.itemValue && five_obj.itemValue!=0 ?Math.round((obj.itemValue-five_obj.itemValue)/five_obj.itemValue*100 ,2) :null;
                                sortList.push(sortObj);
                                if(index==0){
                                    objAry[i].sortFieldAry=['行业', filed_1,filed_2,'与上年增速',filed_3,'与五年前增速'];
                                }
                            })
                            objAry[i].dataList=sortList;
                        }
                    }
                    else if(objAry[i].itemId.substring(0,2)=='20'){//宏观指标，行业排名
                        let itemList=await getItemList({itemId:objAry[i].itemId,pageSize:1});
                        //指标项的最新时间
                        let dateRes= getCurrentDate({
                            itemId:objAry[i].itemId,
                            regionCode:regionCode.value})
                        //行业指标项最新时间、上年、前5年的数据
                        let date=dateRes.data;
                        if(!date){
                            date=(new Date().getFullYear()-3)+'-01-01';
                        }
                        //console.log(date);
                        params.objId=null;
                        params.date=date+','+(new Date(date).getFullYear()-1)+'-01-01,'+(new Date(date).getFullYear()-5)+'-01-01';
                        let res= await getMultiRegionItemDataList(params);
                        let sortList=[];
                        let dateAry=params.date.split(',');
                        itemList.data?.dataList.forEach((t,index)=>{
                            let sortObj={};
                                sortObj['行业']=t.itemName.substring(t.itemName.indexOf('_')+1,t.itemName.lastIndexOf('_'));
                                //本年度的数据
                                let obj= res.data.find(d=>new Date(d.date).getTime()==new Date(dateAry[0]).getTime() && d.itemId==t.itemId);
                                let filed_1=parseTime( new Date(dateAry[0]),'{yyyy}')+'年';
                                sortObj[filed_1]=obj?obj.itemValue:null;
                                //上年度的数据
                                let prev_obj= res.data.find(d=>new Date(d.date).getTime()==new Date(dateAry[1]).getTime() && d.itemId==t.itemId);
                                let filed_2=parseTime( new Date(dateAry[1]),'{yyyy}')+'年';
                                sortObj[filed_2]=prev_obj?prev_obj.itemValue:null;
                                //与上年的增速
                                sortObj['与上年增速(%)']=prev_obj && obj && prev_obj.itemValue && obj.itemValue && prev_obj.itemValue!=0 ?Math.round((obj.itemValue-prev_obj.itemValue)/prev_obj.itemValue*100 ,2) :null;
                                //五年前的数据
                                let five_obj= res.data.find(d=>new Date(d.date).getTime()==new Date(dateAry[2]).getTime() && d.itemId==t.itemId);
                                let filed_3=parseTime( new Date(dateAry[2]),'{yyyy}')+'年';
                                sortObj[filed_3]=five_obj?five_obj.itemValue:null;
                                //与五年前的增速
                                sortObj['与五年前增速(%)']=five_obj && obj && five_obj.itemValue && obj.itemValue && five_obj.itemValue!=0 ?Math.round((obj.itemValue-five_obj.itemValue)/five_obj.itemValue*100 ,2) :null;
                                sortList.push(sortObj);
                                if(index==0){
                                    objAry[i].sortFieldAry=['行业', filed_1,filed_2,'与上年增速(%)',filed_3,'与五年前增速(%)'];
                                }
                            })
                            objAry[i].dataList=sortList;
                    }
                }
                else if(objAry[i].sortType=='chart'){//获取指标信息，单击指标项以横向柱状图显示各省的排名情况
                    //判断指标项是宏观、行业指标项
                    let item=objAry[i].itemId.split(';');
                    let dataList=[];
                    for(var j=0;j<item.length;j++){
                        let itemList=[];
                        if(item[j].substring(0,2)=='20'){
                            itemList=await getItemList({itemId:item[j],pageSize:1})
                        }
                        else{
                            //获取指标项
                            //console.log(item[j])
                            itemList=await getItemListOfEcoIndustry({itemId:item[j],pageSize:1})
                        }
                        if(itemList.code==20000 && objAry[i].industryCode==selIndustryCode.value){
                            dataList=dataList.concat(itemList.data.dataList) 
                        }
                    }
                    objAry[i].dataList=dataList;
                    //默认第一个指标项
                    if(dataList.length>0){
                        await getRegionSortList(objAry[i].industryCode,objAry[i].type, dataList[0]);
                    }
                }
            }
        }
        //获取当前行业的最新时间的省级排名
       
        const getRegionSortList=async(industryCode,type,item)=>{
            regionSortList.value.find(t=>t.industryCode==industryCode && t.type==type).selItemId=item.itemId;
            let params={itemId:item.itemId,regionCode:regionCode.value,objId:industryCode}
            let res= await getRegionItemSort(params);
            //console.log(res);
            if(res.code==20000){
                var index=res.data.dataList?.findIndex((value)=>value.regionCode==regionCode.value);
                var obj=res.data.dataList?.find(t=>t.regionCode==regionCode.value);
                nextTick(()=>{
                    var sortChartDom = document.getElementById(type+"_sort_chart_"+industryCode);
                    var sortChart = echarts.getInstanceByDom(sortChartDom);
                    if(sortChart){
                        sortChart.clear();
                    }
                    else{
                        sortChart = echarts.init(sortChartDom);
                    }
                    
                    let option = {
                        color:colors,
                        tooltip: {
                            trigger: 'axis',
                            axisPointer: {
                            // Use axis to trigger tooltip
                            type: 'shadow' // 'shadow' as default; can also be 'line' or 'shadow'
                            }
                        },
                        legend: {},
                        grid: {
                            left: '3%',
                            right: '4%',
                            bottom: '3%',
                            containLabel: true
                        },
                        xAxis: {
                            type: 'value'
                        },
                        yAxis: {
                            type: 'category',
                            data: res.data.dataList?.map(t=>t.regionName)
                        },
                        graphic: [{
                            left: '10%',
                            top: '25',
                            type: 'text',
                            z: 100,
                            style: {
                                fill: '#002FA7',
                                width: 220,
                                text: obj? obj.regionName+'('+parseTime(new Date(res.data.date),'{yyyy}')+'年)：'+obj.itemValue+res.data.itemUnit+'，排名：'+(obj.itemValue?index+1:'-'):null,
                                font: 'bolder 16px Microsoft YaHei'
                            }
                        }],
                        series: [
                            {
                            name: item.itemName,
                            type: 'bar',
                            // label: {
                            //     show: true
                            // },
                            tooltip: {
                                valueFormatter: function (value) {
                                return (value?value:'-') + item.itemUnit;
                                }
                            },
                            emphasis: {
                                focus: 'series'
                            },
                            data: res.data.dataList?.map(t=>{
                                if(t.regionCode==regionCode.value){
                                   return {
                                       name:t.name,
                                       value:t.itemValue,
                                        itemStyle: {
                                            color: '#e6a23c'
                                        }
                                    }
                                }
                                else{ return {name:t.regionName, value: t.itemValue}}
                                })
                            }
                        ]
                        };
                    option && sortChart.setOption(option);
                    echartsAry.value.push(sortChart);
                })
            }
        }
        //行业报告列表
        //let isReportDownloadRight=0;//报告下载的权限
        const getReportListFun=async()=>{
            reportList.value=[];
            // if(isReportDownloadRight==0){
            //     if(!await getUserRightFun('download',1,router.currentRoute.value.meta.menuId)){
            //         isReportDownloadRight=2;//没有权限下载
            //     }
            //     else{
            //         isReportDownloadRight=1;
            //     }
            // }
            // if(isReportDownloadRight==0 || isReportDownloadRight==2){
            //     return false;
            // }
            let params={
                ecoIndustryCode:selIndustryCode.value,
                startDate: (new Date().getFullYear()-1)+'-01-01',
                pageSize:reportPageSize.value,
                pageIndex:reportPageIndex.value,
            }
            let res=await getEcoIndustryReportList(params);
            //console.log(res);
            if(res.code==20000 && params.ecoIndustryCode==selIndustryCode.value){
                reportTotalCount.value=res.data.totalCount;
                reportList.value=res.data.dataList;
            }
            
        }
        const handleCurrentChangeFun=(current)=>{
            reportPageIndex.value = current;
            //行业报告查询
            getReportListFun();
        }
        //单击菜单锚点到相应的位置
        const goAnchor= (selector) => {
            //selLabel.value=selector;
            const obj=document.querySelector('#'+selector);
                
            let to=obj.offsetTop-60;
           
            //document.querySelector('.current').classList.remove('current');
            //obj.classList.add("current");
            scrollTo('.affix-container .el-scrollbar__wrap',to,800);
        }
        const reportLinkFun=(id)=>{  
            // const { href } = router.resolve({
            // path: '/pdf',
            // params:{
            //         num:1
            //     }
            // });
            const { href }=router.resolve({path:'/pdf',query:{id:id}}) // resolve方法
            window.open(href, "_blank");
        }
        // let isShowHeader=ref(false);//是否显示

        // // 滚动加载
        // const lazyLoading= ()=> {
        //     const scrollTop = document.querySelector('.affix-container .el-scrollbar__wrap').scrollTop;// 滚动条滚动时，距离顶部的距离
            
        //     if(scrollTop>200){
        //         isShowHeader.value=true;
                
        //     }
        //     else{
        //         isShowHeader.value=false;
        //     }
        // }
        //自适应
        let listener=()=> {
        echartsAry.value.forEach(element => {
            element.resize();
        });
        }
        window.addEventListener('resize', listener)//监听resize事件
        //监听地区的改变
        watch(
            ()=>regionCode.value,
            (newVal,oldVal)=>{
                //console.log('监听全局变量regionCode',newVal);
                if(!oldVal && newVal){
                    //执行
                    getIndustryListFun()
                }
            },
            {
                immediate:true,// 这个属性是重点啦
                //deep:true // 深度监听的参数
            }
        )
        
        onBeforeUnmount(()=>{//销毁之前
            //window.removeEventListener('scroll',lazyLoading)
            window.removeEventListener('resize',listener);
            echartsAry.value.forEach(element => {
            if (element) {
                element.clear(); //清空图表
                element.dispose(); //释放图表组件
                element = null;
            }
            }); 
        })
        onMounted(()=>{
            //initAMap();
            //document.querySelector('.affix-container .el-scrollbar__wrap').addEventListener('scroll',lazyLoading);//监听
        })
        return{
            windowHeight,
            selIndustryFun,        
            industryList,
            selIndustryCode,
            industryInfoList,
            regionSortList,
            getRegionSortList,
            reportTotalCount,
            parseTime,
            reportPageSize,
            reportPageIndex,
            reportList,
            handleCurrentChangeFun,
            goAnchor,
            reportLinkFun
        }
    },
}
</script>
<style lang='scss' scoped>
@import '@/styles/variables.scss';
.anchor_txt{
    margin-left:10px;
}

.span-txt{
    line-height: 30px;
    font-weight: bold;
    color: $specialtext-color;
}
.cd_page_nav {
    height: 45px;
    padding: 0 20px;
    background-color: $themeColor;
    transition: all .5s;
    margin:0 10px 20px 10px;
    span{
        float: left;
        font-size: 18px;
        border-bottom: 2px solid transparent;
        line-height: 42px;
        margin-right: 60px;
        cursor: pointer;
        color: #fff;
    }
}
.box{
    float: left;
    width: calc(50% - 20px);
    margin: 10px;
    padding: 10px;
    background: #fff;
    border-radius: 4px;
    position: relative;
    box-shadow: rgba(0, 0, 0, 0.06) 0px 2px 3px 0px;
}
.box-item{
    border:1px solid $bordercolor;
    border-radius: 4px;
    height: 100%;
    overflow-y:auto;
    li{
        line-height: 35px;
        cursor: pointer;
        padding:0 10px;
        border-radius: 4px;
    }
    li.active{
        background-color: $themeColor;
        color:#fff;
    }
}
</style>